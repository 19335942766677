class Event {

    /**
     * Function to trigger a (custom) event
     * @param target
     * @param name
     * @param detail
     */
    static trigger(target, name, detail) {
        target.dispatchEvent(
            new CustomEvent(name, {
                bubbles: true,
                cancelable: true,
                detail: detail
            })
        );
    }

    /**
     * Listener for multiple events
     * @param event
     * @param selector
     * @param fn
     */
    static on(event, selector, fn) {

        //  Split on space
        let events = event.split(' ');

        //  Check if selector is window pass the window object, otherwise it will be the document providing the selector
        if (selector === window) {

            if (events.length > 0) {

                for (let i = 0; i < events.length; i++) {

                    window.addEventListener(events[i], fn, false);
                }

            }

        } else {

            let selectors = document.querySelectorAll(selector);

            if (events.length > 0) {

                for (let i = 0; i < events.length; i++) {

                    let event = events[i];

                    if (selectors.length > 0) {

                        for (let i = 0; i < selectors.length; i++) {

                            let element = selectors[i];

                            element.addEventListener(event, fn, false);
                        }

                    }

                }

            }

        }

    }

}

module.exports = Event;
