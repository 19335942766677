"use strict";

const $ = require("jquery");

class VideoPlayer {

    constructor() {

        $(".ce-video").each(function() {

            const player = $(this);
            const playButton = player.find(".play-button");
            const videoId = playButton.attr("data-id");
            const videoSource = playButton.attr("data-source");

            const posterImage = player.find(".poster-image");
            const video = player.find(".video");

            playButton.on("click", function() {

                posterImage.addClass("hide-poster-image");
                video.addClass("show-video");

                if (videoSource === 'vimeo') {

                    const iframe = document.getElementById(videoId);
                    const player = new Vimeo.Player(iframe);
                    player.play();

                } else if (videoSource === 'youtube') {
                    
                    const player = new YT.Player(videoId, {
                        events: { 'onReady': () => { player.playVideo() } }
                    });                     

                }

            })

        });

    }

}

module.exports = VideoPlayer;
