'use strict';

class Slider {

    constructor() {

        this.keyvisualSwiper();
        this.imageSlider();

    }

    keyvisualSwiper() {

        const keyvisual = document.querySelectorAll('.keyvisual__swiper');

        if (keyvisual.length > 0) {

            const Swiper = require('./../../../../build/node_modules/@components/swiper/package/js/swiper.min.js');

            for (const slider of keyvisual) {

                const sliderInstance = new Swiper(slider.querySelector('.swiper-container'), {
                    speed: 800,
                    simulateTouch: true,
                    spaceBetween: 0,
                    slidesPerView: 1,
                    watchOverflow: true,
                    navigation: {
                        nextEl: slider.querySelector('.next-slide'),
                        prevEl: slider.querySelector('.prev-slide')
                    },
                    effect: 'cards'
                });

                sliderInstance.on('slideChangeTransitionStart', () => updateIntro());

            }

            const updateIntro = () => {

                const active = document.querySelector('.swiper-slide-active .swiper-slide-text');
                const intro = document.querySelector('.keyvisual .intro');
                const slider = document.querySelector('.swiper-container');

                if (window.innerWidth >= 1230) {
                    slider.style.marginTop = intro.clientHeight -580 + "px";
                } else if (window.innerWidth >= 1024 && window.innerWidth < 1230) {
                    slider.style.marginTop = intro.clientHeight -380 + "px";
                } else {
                    slider.style.marginTop = intro.clientHeight -280 + "px";
                }

                intro.innerHTML = `<p>${active.innerText}</p>`;

            }

            const observeBodyWidth = new ResizeObserver( entries => {
                entries.forEach( () => {
                    updateIntro();
                    return;
                })
            });

            observeBodyWidth.observe( document.body );
            updateIntro();

        }

    }

    imageSlider() {

        const imageSliders = document.querySelectorAll('.ce-image-slider');

        if (imageSliders.length > 0) {

            const Swiper = require('./../../../../build/node_modules/@components/swiper/package/js/swiper.min.js');

            for (const slider of imageSliders) {

                new Swiper(slider.querySelector('.swiper-container'), {
                    speed: 800,
                    simulateTouch: true,
                    spaceBetween: 0,
                    slidesPerView: 1,
                    watchOverflow: true,
                    navigation: {
                        nextEl: slider.querySelector('.next-slide'),
                        prevEl: slider.querySelector('.prev-slide')
                    },
                    effect: 'cards'
                });

            }

        }

    }

}

module.exports = Slider;
