/**
 *
 * Scroller represents an internal link scroller.
 * The href="#target" (anchor) or the data-scroll="#target" (any other element) is the target to scroll to. Make sure an ID is unique...
 * data-scroll-offset="#header" is an optional setting to subtract the height of given element. Only one element allowed (either ID or CLASS)
 *
 * Example:
 * <a href="#main" class="scroller" data-scroll data-scroll-offset="#header">Text</a>
 *
 */

'use-strict';

class Scroller {

    constructor() {

        const $ = require('jquery');

        let $scrollTrigger = $('[data-scroll]');

        $scrollTrigger.on('click', function (ev) {
            ev.preventDefault();

            let $this = $(this);
            let scroll = true;
            let target = false;
            let offset = 0;

            //  Check for data-scroll first
            if ($this.data('scroll') !== '') {

                target = $this.data('scroll');

                //  Check for href as fallback
            } else if ($this.attr('href')) {

                target = $this.attr('href');

                //  If both cases fail, do nothing ...
            } else {

                scroll = false;

            }

            //  Offset (optional)
            if ($this.data('scroll-offset')) {

                offset = $($this.data('scroll-offset')).height();

            }

            if (scroll && target) {

                let targetTop = $(target).position().top;

                App.Globals.$scrollAnimators.animate({
                    scrollTop: targetTop - offset
                });

            }

        });

    }

}

module.exports = Scroller;
