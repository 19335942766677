"use strict";

const $ = require("jquery");

class General {
    constructor() {
        require("./../../../../build/node_modules/@components/foundation-sites/dist/js/foundation.min.js");

        App.Globals.$document.foundation();
    }
}

module.exports = General;
