/**
 * Add components by event and in your desired order
 * @type {[*]}
 */
let components = [
    [
        "ready",
        [
            require("./../components/animation"),
            // require("./../lib/equal-heights"),
            require("./../components/cookie-notice"),
            require("./../components/general"),
            require("./../components/form"),
            require("./../components/scroller"),
            require("./../components/trigger"),
            require("./../components/filter"),
            require("./../components/filter-toggle"),
            // require('./../components/filter-lazy-load'),
            require("./../components/slider"),
            // require('./../components/waypoints'),
            // require("./../components/popup"),
            require("./../components/header"),
            require("./../components/video-player"),
        ],
    ],
];

module.exports = components;
