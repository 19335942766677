"use strict";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
gsap.registerPlugin(ScrollTrigger);

class Animation {
    constructor() {
        this.reveal();
        this.doorwaySlider();
        this.contentSlider();
    }

    reveal = () => {
        function throttle(callback, delay) {
            let time = Date.now();
            return function () {
                if (time + delay - Date.now() < 0) {
                    callback();
                    time = Date.now();
                }
            };
        }

        function reveal() {
            const items = document.querySelectorAll(".reveal");
            const className = "active";

            for (const item of items) {
                const windowHeight = window.innerHeight;
                const elementTop = item.getBoundingClientRect().top;
                const offset = item.getAttribute("data-offset") || 0;

                if (elementTop < windowHeight - offset) {
                    if (!item.classList.contains(className)) {
                        item.classList.add(className);
                    }
                }
            }
        }

        window.addEventListener("scroll", throttle(reveal, 250));
        reveal();
    }

    doorwaySlider = () => {
        const doorwaysSliders = document.querySelectorAll(".ce-doorway-slider");

        if (doorwaysSliders) {

            for (const slider of doorwaysSliders) {
                let items = slider.querySelectorAll(".scroller ul li");

                if (items) {

                    items = gsap.utils.toArray(items);
                    const container = slider.querySelector(".scroller");
                    const scrollHint = slider.querySelector(".scrollhint");
                    const listElement = slider.querySelector(".scroller ul");

                    let instance = null;

                    ScrollTrigger.matchMedia({
                        "(min-width: 768px)": () => {
                            instance = gsap.to(items, {
                                x: () =>
                                    -(
                                        container.scrollWidth -
                                        document.documentElement.clientWidth +
                                        180
                                    ) + "px",
                                scrollTrigger: {
                                    trigger: slider,
                                    pin: true,
                                    start: "top +90 top",
                                    scrub: 1,
                                    snap: false,
                                    end: () =>
                                        "+=" +
                                        (container.offsetHeight - 180) +
                                        "px",
                                    invalidateOnRefresh: true,
                                    onUpdate: self => {
                                        if (scrollHint) {
                                            if (self.progress > 0 && self.progress < 1) {
                                                scrollHint.classList.remove("hidden");
                                            } else {
                                                scrollHint.classList.add("hidden");
                                            }
                                        }
                                    },
                                },
                            });
                        },
                    });

                    const observeSlider = new ResizeObserver( entries => {
                        entries.forEach( (entry) => {
                            checkInstance(entry.contentRect.width);
                            return;
                        })
                    });
                    observeSlider.observe( slider );

                    const checkInstance = (elementWidth) => {

                        if (instance !== null) {

                            let itemsWidth = 0;
                            for (const item of items) {
                                itemsWidth += item.clientWidth;
                            }

                            // add scroller padding and gap to itemsWidth
                            itemsWidth += this.valueFromCSS(container, "padding-left");
                            itemsWidth += this.valueFromCSS(container, "padding-right");
                            itemsWidth += items.length * this.valueFromCSS(listElement, "gap");

                            if (elementWidth >= itemsWidth) {
                                instance.scrollTrigger.disable(true);
                                scrollHint.classList.add("hidden");
                            }else {
                                instance.scrollTrigger.enable(true);
                            }

                        }
                    }

                    checkInstance(container.clientWidth);

                }
            }
        }
    }

    valueFromCSS = (element, property) => {
        return parseInt(window.getComputedStyle(element).getPropertyValue(property)) ?? 0;
    }

    contentSlider = () => {
        const contentSliders = document.querySelectorAll(".ce-content-slider");

        if (contentSliders) {

            for (const slider of contentSliders) {
                let items = slider.querySelectorAll(".scroller ul li");

                if (items) {

                    items = gsap.utils.toArray(items);
                    const container = slider.querySelector(".scroller");
                    const scrollHint = slider.querySelector(".scrollhint");

                    ScrollTrigger.matchMedia({
                        "(min-width: 1024px)": () => {
                            gsap.to(items, {
                                x: () =>
                                    -(
                                        container.scrollWidth -
                                        document.documentElement.clientWidth + 128
                                    ) + "px",
                                ease: "slow",
                                scrollTrigger: {
                                    trigger: slider,
                                    pin: true,
                                    start: "top +60",
                                    scrub: 1,
                                    snap: false,
                                    end: () => "+=" + (container.offsetHeight) + "px",
                                    invalidateOnRefresh: true,
                                    onUpdate: self => {
                                        if (scrollHint) {
                                            if (self.progress > 0 && self.progress < 1) {
                                                scrollHint.classList.remove("hidden");
                                            } else {
                                                scrollHint.classList.add("hidden");
                                            }
                                        }
                                    },
                                }
                            })
                        },
                    })
                }
            }
        }
    }
}

module.exports = Animation;
