const $ = require("jquery");

module.exports = {
    $app: $(this),
    $document: $(document),
    $window: $(window),
    window: window,
    $body: $("body"),
    body: document.body,
    $main: $("#main"),
    $scrollAnimators: $("html, body"),
    debug: false,
};
