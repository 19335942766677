'use strict';

class App {

    constructor(window, document) {

        this.Globals = require('./config/cached-elements');
        this.Settings = require('./config/settings');
        this.Components = require('./config/components');

        let AllComponents = new Map(this.Components);

        for (let [event, components] of AllComponents) {

            //  Add event
            this.Globals.$document.on(event, function () {

                //  Load the components for current event
                for (let Component of components) {
                    new Component();
                }

            });

        }

        document.body.classList.remove('preload');

    }

}

window.App = new App(this, document);
