'use strict';

const $ = require('jquery');

class Form {

    constructor() {

        this.renderForm();

        App.Globals.$document
            .on('gform_post_render', () => {

                this.renderForm();

            })
            .on('gform_confirmation_loaded', (event, formId) => {

                this.renderSuccess(event, formId);

            });

    }

    toggleLabel() {

        this.$labels
            .removeClass('selected')
            .filter(':has(:checked)')
            .addClass('selected');

    }

    renderSuccess(event, formId) {

        //  Success handler

    }

    renderForm() {

        //  Remove all tabindexes to avoid switching between forms
        $('input, select, textarea').removeAttr('tabindex');

        //  Custom checkboxes and radio's
        let $choiceBoxes = $('.gfield_radio li, .gfield_checkbox li');

        $choiceBoxes.closest('.gfield').addClass('checkbox-radio-field');

        if ($choiceBoxes.length > 0) {

            $choiceBoxes.each(function () {

                let $this = $(this);
                let oldInput = $this.find('input');
                let className = $this.parent().attr('class').replace('gfield_', '');

                $this
                    .find('label')
                    .addClass(className)
                    .prepend(oldInput);
            });

        }

        this.$labels = $('.checkbox, .radio').closest('label');

        this.$labels.on('click', () => {
            this.toggleLabel();
        });

        this.toggleLabel();

        let $validationMessage = $('.validation_message');

        $validationMessage.each(function () {

            let $this = $(this);
            let validateMessage = $this.html();

            if (validateMessage === '') {
                $this.remove();
            }

        });

    }

}

module.exports = Form;
