'use strict';

const $ = require('jquery');

class Filter {

    constructor() {

        const $filter = $('[data-ajax-area]');

        if ($filter.length > 0) {

            const Template = require('./../lib/template');
            const LocationBar = require('./../lib/location-bar');
            const locationBar = new LocationBar();

            let postType = $filter.data('post-type');
            let $resultHolder = $filter.find('[data-results-holder]');
            let $paginationHolder = $filter.find('[data-pagination-holder]');
            let $filterForm = $filter.find('[data-filter-form]');
            let $filterFormFields = $filterForm.find('input[type="checkbox"], input[type="radio"], select');

            locationBar.start({
                pushState: true
            });

            locationBar.onChange(function (path) {

                App.Globals.$document.trigger('site:filter:start', path);

                if (App.Globals.debug) {
                    console.log('path: /' + path);
                }

                $.ajax({
                    url: '/' + path,
                    data: {
                        json: true
                    },
                    dataType: 'json'
                }).done(function (data) {
                    data.ajax = true;

                    if ($resultHolder.length > 0) {
                        Template.render($resultHolder, rk_globals.theme_path + '/views/parts/' + postType + '/set.twig', data);
                    }

                    if ($paginationHolder.length > 0) {
                        Template.render($paginationHolder, `${rk_globals.theme_path}/views/parts/pagination.twig`, data);
                    }

                    App.Globals.$document.trigger('site:filter:end', path);

                    // Scroll to the top of the results
                    App.Globals.$scrollAnimators.animate({
                        scrollTop: $filter.offset().top - $('#header').height()
                    });

                    // Force Scroll
                    window.dispatchEvent(new CustomEvent('scroll'))

                });

            });

            //  Handle all field of the form to process the filters (taxonomies)
            $filterFormFields.on('change', function () {
                $filterForm.submit();
            });

            //  Handle form submit
            $filterForm.on('submit', function (ev) {
                ev.preventDefault();

                let hash = window.location.hash;
                let pathname = window.location.pathname;
                //  Strip paginator from filter.
                //  When you're on page 2 and use the filter you need a new search, not a filtered page 2
                let url = pathname.replace(/page\/[0-9]\//g, '');

                //  Serialized form data
                let data = $(this).find(':input').filter(function (index, element) {
                    return $(element).val() != '';
                }).serialize();

                if (data) {
                    url += '?' + decodeURIComponent(data);
                }

                if (hash) {
                    url += hash;
                }

                App.Globals.$document.trigger('site:user:filter', decodeURIComponent(data));

                //  Update URL
                locationBar.update(url, { trigger: true });


            });

            //  Handle paginator
            $filter.on('click', '.pagination a', function (ev) {
                ev.preventDefault();

                let href = this.href;
                let url = href.replace(/^.*\/\/[^\/]+/, '');
                let hash = window.location.hash;

                if (hash) {
                    url += hash;
                }

                //  Update URL
                locationBar.update(url, { trigger: true });
            
            });

        }

    }

}

module.exports = Filter;
