"use strict";

const $ = require("jquery");

class FilterToggle {
    constructor() {
        const filter = $(".inspiration__filter .filter");
        const toggle = $(".inspiration__filter .inspiration__filter__button");

        function init() {
            toggle.on("click", function (e) {
                e.preventDefault();
                if (toggle.hasClass("plus")) {
                    toggle.removeClass("plus");
                    toggle.addClass("minus");
                    filter.addClass("show");
                } else {
                    toggle.removeClass("minus");
                    toggle.addClass("plus");
                    filter.removeClass("show");
                }
            });
        }

        init();
    }
}

module.exports = FilterToggle;
