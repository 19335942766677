const $ = require('jquery');
const Twig = require ('./../../../../build/node_modules/@components/twigjs-bower/twig/twig.js');
const Event = require('./events');

const Template = {

    /**
     * Renders Twig templates through TwigJS
     * @param $element
     * @param templatePath
     * @param data
     * @param append
     * @param ajaxView
     */
    render: function ($element, templatePath, data, append, ajaxView) {

        /**
         * Add "resize" filter to TwigJS
         * @return image
         */
        Twig.extendFilter('resize', function (value, params) {

            if (value && value.indexOf('undefined') !== -1) return;

            let url = '/?resize&img=' + value + '&width=' + params[0] + '&height=' + params[1];
            let image = value;

            //  /?resize&img=https://webite-url.test/wp-content/uploads/Screen-Shot-2018-01-22-at-17.01.34.png&width=2&height=2
            $.ajax({
                url: url,
                dataType: 'json',
                async: false
            }).done(function (data) {

                //  Return resized image
                image = data.resized_image;
            });

            return image;
        });

        /**
         * Add "page_url" filter to TwigJS
         * @return link
         */
        Twig.extendFilter('page_url', function (value) {

            if (!value) return;

            let url = '/' + rk_globals.current_lang + '/api/page_url/' + value;
            let link = '';

            $.ajax({
                url: url,
                dataType: 'json',
                async: false
            }).done(function (data) {

                link = data.url;

            });

            return link;
        });

        /**
         * Add "translated_date" filter to TwigJS
         * @return link
         */
        Twig.extendFilter('translated_date', function (value, formats) {

            if (!value) return;

            if ( rk_globals.current_lang.length > 0 ) {
                let moment = require('./../../../../build/node_modules/@components/moment/min/moment-with-locales');
                moment.locale(rk_globals.current_lang);

                let newDate = moment(new Date(value)).format(formats[0].js);

                return newDate.replace('.', '');
            }

            return value;
        });

        //  DEBUG
        if (App.Globals.debug) {
            console.log('templatePath: ' + templatePath);
        }

        /**
         * Load Twig template
         * Output HTML
         * Callback
         */

        let basePath = rk_globals.theme_path + '/views/' + (ajaxView ? 'ajax/' : '');

        //  DEBUG
        if (App.Globals.debug) {
            console.log('basePath: ' + basePath);
        }

        Twig.twig({
            base: basePath,
            href: templatePath,
            allowInlineIncludes: true,
            load: function (template) {

                let html = template.render(data);

                if (append) {
                    $element.append(html);
                } else {
                    $element.html(html);
                }

                $element.foundation();
                Event.trigger(window, 'site:ajax-ready', $element);
                Event.trigger(window, 'resize');

                //  DEBUG
                if (App.Globals.debug) {
                    console.log('HTML: ' + html);
                }
            }
        });

    },

    /**
     * Renders Twig templates for Google Maps InfoWindows
     * @param templatePath
     * @param data
     * @param markerId
     */
    renderInfoWindow: function (templatePath, data, markerId) {

        /**
         * Add "resize" filter to TwigJS
         * @return image
         */
        Twig.extendFilter('resize', function (value, params) {

            let url = '/?resize&img=' + value + '&width=' + params[0] + '&height=' + params[1];
            let image = value;

            //  /?resize&img=https://webite-url.test/wp-content/uploads/Screen-Shot-2018-01-22-at-17.01.34.png&width=2&height=2
            $.ajax({
                url: url,
                dataType: 'json',
                async: false
            }).done(function (data) {

                //  Return resized image
                image = data.resized_image;

            });

            return image;
        });

        /**
         * Add "page_url" filter to TwigJS
         * @return link
         */
        Twig.extendFilter('page_url', function (value) {

            if (!value) return;

            let url = '/' + rk_globals.current_lang + '/api/page_url/' + value;
            let link = '';

            $.ajax({
                url: url,
                dataType: 'json',
                async: false
            }).done(function (data) {

                link = data.url;

            });

            return link;

        });

        /**
         * Add "translated_date" filter to TwigJS
         * @return link
         */
        Twig.extendFilter('translated_date', function (value, formats) {
            if (!value) return;

            if ( rk_globals.current_lang.length > 0 ) {
                let moment = require('./../../../../build/node_modules/@components/moment/min/moment-with-locales');
                moment.locale(rk_globals.current_lang);

                let newDate = moment(new Date(value)).format(formats[0].js);

                return newDate.replace('.', '');
            }

            return value;
        });

        //  DEBUG
        if (App.Globals.debug) {
            console.log('templatePath: ' + templatePath);
        }

        /**
         * Load Twig template
         * Output HTML
         * Callback
         */

        let basePath = rk_globals.theme_path + '/views/ajax/';

        //  DEBUG
        if (App.Globals.debug) {
            console.log('basePath: ' + basePath);
        }

        Twig.cache();

        let output = Twig.twig({
            id: 'location-' + markerId,
            base: basePath,
            href: templatePath,
            allowInlineIncludes: true,
            async: false
        });

        return Twig.twig({ref: 'location-' + markerId}).render(data);
    }
};

module.exports = Template;
