"use strict";

const $ = require("jquery");

class Header {
    constructor() {
        const logo = $(".logo__container");
        const primary = $(".primary-menu");
        const secondary = $(".secondary-menu");
        const header = $("#header");
        let menu_width = 0;
        const trigger = $(".menu-trigger");
        const trigger_label = $(".menu-trigger div");
        const body = $("body");
        let menu_open = body.hasClass("menu-open");

        function getMenuWidth() {
            menu_width = 2 * 64 + primary.innerWidth() + secondary.innerWidth();
        }
        
        function onScroll() {
            if ($(window).scrollTop() >= 200) {
                logo.addClass("logo__resize-on-scroll");
                header.css("background-color","rgba(23,29,26,1)");
                return;
            }
            if (logo.hasClass("logo__resize-on-scroll")) {
                logo.removeClass("logo__resize-on-scroll");
                header.css("background-color","rgba(0,0,0,0)");
            }
        }

        function onResize() {
            getMenuWidth();
            switchMenu();
        }

        function switchMenu() {

            if (menu_open) {
                return;
            }

            if (menu_width >= $(window).width()) {
                primary.css("display", "none");
                secondary.css("display", "none");
                trigger.css("display", "flex");

                initMenu();

            } else {
                trigger.css("display", "none");
                primary.css("display", "flex");
                secondary.css("display", "flex");

                trigger.removeClass("nav-open");
                body.removeClass("menu-open");
                trigger_label.text("Menu");

                resetMenu();

            }
        }

        function resetMenu() {

            $("nav .has_submenu").each(function () {

                const active = $(this);
                const submenu = active.next();
              
                active.addClass("plus");
                active.removeClass("minus");
                active.removeClass("active");
                submenu.removeClass("submenu_open");

                if (!menu_open) {
                    submenu.children("ul").css("display","flex");
                }else{
                    submenu.children("ul").css("display","none"); 
                }             

                if ($(window).scrollTop() >= 200) {
                    header.css("background-color","rgba(23,29,26,1)");
                }else{
                    header.css("background-color","rgba(0,0,0,0)");
                }
            });

        }

        function initMenu() {

            $("nav .has_submenu").each(function () {

                const active = $(this);
                const submenu = active.next();
        
                submenu.children("ul").css("display","none"); 

            });

        }

        function init() {
            trigger.on("click", function (e) {
                e.preventDefault();
                if (trigger.hasClass("nav-open")) {
                    trigger.removeClass("nav-open");
                    body.removeClass("menu-open");
                    menu_open = false;
                    trigger_label.text("Menu");
                    if (menu_width >= $(window).width()) {
                        primary.css("display", "none");
                        secondary.css("display", "none");
                    }
                    if (menu_width <= $(window).width()) {
                        trigger.css("display", "none");
                    }
                    header.css("background-color","rgba(0,0,0,0)");
                    resetMenu();
                } else {
                    trigger.addClass("nav-open");
                    body.addClass("menu-open");
                    menu_open = true;
                    primary.css("display", "flex");
                    secondary.css("display", "flex");
                    trigger_label.text("Sluit");
                    header.css("background-color","rgba(23,29,26,1)");
                    initMenu();
                }
            });

            $("nav .has_submenu").on("click", function (e) {
                
                const active = $(this);
                const submenu = active.next();
            
                if (menu_open) {
                    
                    e.preventDefault();

                    if(submenu.hasClass("submenu_open")){
                        active.addClass("plus");
                        active.removeClass("minus");
                        active.removeClass("active");
                        submenu.removeClass("submenu_open");
                        submenu.children("ul").css("display","none");
                    }else{
                        active.addClass("minus");
                        active.removeClass("plus");
                        active.addClass("active");
                        submenu.addClass("submenu_open");
                        submenu.children("ul").css("display","flex");
                    }
                    
                }
            });

            $(".submenu")
                .on("mouseenter", function (e) {
                    e.preventDefault();

                    const active = $(this);

                    if (!menu_open) {
                        active.parent().children("a").addClass("active");
                    }

                })
                .on("mouseleave", function (e) {
                    e.preventDefault();

                    const active = $(this);

                    if (!menu_open) {
                        active.parent().children("a").removeClass("active");
                    }

                })
                .on("click", function (e) {

                    const active = $(this);

                    if (menu_open) {
                        active.parent().children("a").addClass("active");
                    }

                });

            $(".submenu a")
                .on("mouseenter", function () {
                    
                    const active = $(this);

                    if(!menu_open){
                        active
                            .parent()
                            .siblings()
                            .children("a")
                            .not(active)
                            .addClass("inactive");
                        const image = active
                            .parent()
                            .parent()
                            .parent()
                            .children("img");

                        if (active.attr("data-image") !== "none") {
                            image.attr("src", active.attr("data-image"));
                        } else {
                            image.attr("src", image.attr("data-image"));
                        }
                    }

                })
                .on("mouseleave", function () {

                    const active = $(this);

                    if(!menu_open){
                        active
                            .parent()
                            .siblings()
                            .children("a")
                            .not(active)
                            .removeClass("inactive");
                        const image = active
                            .parent()
                            .parent()
                            .parent()
                            .children("img");
                        image.attr("src", image.attr("data-image"));
                    }
                });

            window.addEventListener("scroll", onScroll);
            window.addEventListener("resize", onResize);
            onResize();
        }

        init();
    }
}

module.exports = Header;
